<template>
  <div class="pt-5">
    <!-- <teacher-header></teacher-header> -->
    <h1 class="my-2" style="margin-left:2.2rem">Daily Diary</h1>
    <div class="mt-5">
      <vx-card class="daily-diary-list-card">
        <div
          class="datepicker-wrapper hasIcon"
          style="width: 30%; margin-bottom: 30px"
        >
          <datepicker
            icon="icon icon-user"
            icon-pack="feather"
            :format="dateFormat"
            v-model="selectedDate"
            class="w-full"
          ></datepicker>
          <vs-icon icon="calendar_today" class="icon"></vs-icon>
        </div>

        <!--        <div class="flex datepicker-wrap" style="margin-bottom: 30px">
            <span class="btn btn-outline-primary"
            ><i class="feather icon-chevron-left" @click="previousWeek"></i
            ></span>
          <span class="reportrange-text">
              <date-picker
                v-model="week"
                type="week"
                placeholder="Select week"
                prefix-class="xmx"
                ref="picker"
                @change="pickerChange"
                :lang="lang"
                :clearable="false"
                :show-week-number="false"
                :icon-calender="false"
              >
                <template v-slot:input="{ emit }">{{ dateRange }}</template>
              </date-picker>
            </span>
          <span class="btn btn-outline-primary"
          ><i class="feather icon-chevron-right" @click="nextWeek"></i
          ></span>
        </div>-->
      </vx-card>

      <vx-card class="daily-diary-list-card mt-10">
        <vs-tabs
          class="custom-tabs-ui negative-tab teacher-dd"
          v-model="activeTab"
        >
          <vs-tab label="Plan">
            <div>
              <planned-activity
                v-if="teacher"
                :teacher="teacher"
                :date="selectedDate"
                :startDate="startDate"
                :endDate="endDate"
              ></planned-activity>
            </div>
          </vs-tab>
          <vs-tab label="Outcome">
            <div>
              <outcome-activity
                v-if="teacher"
                :teacher="teacher"
                :date="selectedDate"
                :startDate="startDate"
                :endDate="endDate"
              ></outcome-activity>
            </div>
          </vs-tab>
        </vs-tabs>
      </vx-card>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
// import TeacherHeader from "../TeacherHeader";
import PlannedActivity from "./PlannedActivity";
import OutcomeActivity from "./OutcomeActivity";
import DatePicker from "vue2-datepicker";
import moment from "moment";

require("../../../../assets/scss/datepicker.scss");

export default {
  components: { Datepicker, PlannedActivity, OutcomeActivity, DatePicker },
  data() {
    return {
      activeTab: 0,
      dateFormat: "dsu MMMM yyyy",
      selectedDate: "",
      week: new Date(),
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      },
      dateRange: this.startOfWeek() + " - " + this.endOfWeek(),
      startDate: this.startOfWeek(),
      endDate: this.endOfWeek()
    };
  },
  methods: {
    async getTodayDate() {
      this.selectedDate = await new Date().toISOString().slice(0, 10);
    },
    pickerChange(value) {
      this.startDate = moment(value)
        .startOf("week")
        .format("YYYY-MM-DD");
      this.endDate = moment(value)
        .endOf("week")
        .format("YYYY-MM-DD");
      /*let sDate = this.startOfWeek(value, true);
      let eDate = this.endOfWeek(value, true);
      this.startDate = sDate;
      this.endDate = eDate;
      this.dateRange = sDate + " - " + eDate;*/
    },
    previousWeek() {
      let currentDate = this.startDate;
      let d = new Date(currentDate);
      let a = d.setDate(d.getDate() - 7);
      this.startDate = this.startOfWeek(a);
      this.endDate = this.endOfWeek(a);
      this.dateRange = this.startDate + " - " + this.endDate;
    },
    nextWeek() {
      let currentDate = this.startDate;

      let d = new Date(currentDate);

      let a = d.setDate(d.getDate() + 7);

      this.startDate = this.startOfWeek(a);
      this.endDate = this.endOfWeek(a);
      this.dateRange = this.startDate + " - " + this.endDate;
    },
    startOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);
      let diff =
        date.getDate() -
        date.getDay() +
        (date.getDay() === 0 ? -7 : 1) +
        (fromPicker ? 1 : 0);
      this.week = date;
      return new Date(date.setDate(diff)).toISOString().slice(0, 10);
    },
    endOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);

      let lastDate =
        date.getDate() - (date.getDay() - 1) + 6 + (fromPicker ? 1 : 0);

      return new Date(date.setDate(lastDate)).toISOString().slice(0, 10);
    }
  },
  mounted() {
    this.getTodayDate();
    //this.getTeacherDailyComment(this.teacher._id);
  },
  computed: {
    teacher() {
      return this.$store.state.AppActiveUser;
    },
    disableDatePicker() {
      if (this.teacher.role != "leadTeacher") {
        if (this.activeTab == 1) {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    async activeTab(tab) {
      if (this.teacher.role != "leadTeacher") {
        if (tab == 1) {
          this.selectedDate = new Date();
        }
      }
    },

    async selectedDate(response) {
      let todayDate = new Date();
      let selectedDate = new Date(response);

      let selectedDateForHeading =
        selectedDate.getMonth() +
        1 +
        "/" +
        selectedDate.getDate() +
        "/" +
        selectedDate.getFullYear();

      if (todayDate.setHours(0, 0, 0, 0) == selectedDate.setHours(0, 0, 0, 0)) {
        this.dateFormat = "dsu MMMM yyyy (Today)";
        this.dateTitle = "Today " + selectedDateForHeading;
      } else {
        this.dateFormat = "dsu MMMM yyyy";
        this.dateTitle = selectedDateForHeading;
      }
      this.date = moment(this.date).format("YYYY-MM-DD");
      await this.pickerChange(this.selectedDate);
    }
  }
};
</script>
