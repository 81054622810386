<template>
  <div id="print-daily-diary-div">
    <!--<div class="card-header pl-0">
      <div class="datepicker-wrapper hasIcon">
        <vs-icon icon="calendar_today" class="icon"></vs-icon>
        <datepicker
          icon="icon icon-user"
          icon-pack="feather"
          :format="dateFormat"
          v-model="selectedDate"
          class="w-full"
        ></datepicker>
      </div>
    </div>-->
    <div
      id="data-list-thumb-view"
      class="data-list-container daily-diary-table plan"
    >
    <h3 class="date-div" style="display: none">Planned activities for date {{ selectedFormatedDate }}</h3>



      <table class="w-full">
        <thead>
          <tr>
            <th
              class="daily-dairy-checkbox"
              v-if="teacher.role == 'leadTeacher'"
            >
              &nbsp;
            </th>
            <th class="contributer">Contributor</th>
            <th class="activity">Activity</th>
            <th class="description">Description</th>
            <th class="core-skill text-center">Core Skill</th>
            <th class="ways-of-learning text-center">Ways of Learning</th>
            <th class="stream text-center">STREAM</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="plannedActivities.length > 0"
            v-for="(activity, index) in plannedActivities"
            :key="activity._id"
            :class="
              checkBoxValue[index]
                ? 'checked daily-diary-checklist'
                : 'daily-diary-checklist not-checked'
            "
          >
            <td
              valign="top"
              class="daily-dairy-checkbox"
              v-if="teacher.role == 'leadTeacher'"
            >
              <vs-checkbox v-model="checkBoxValue[index]"></vs-checkbox>
            </td>
            <td valign="top" class="teacher-pic">
              <div class="flex items-center">
                <vs-avatar
                  size="40px"
                  class="ml-0 mr-6"
                  :src="
                    'undefined' != typeof activity.contributor
                      ? activity.contributor[0].profileImage
                      : $defaultImage
                  "
                />
                <div class="fullname">
                  {{ activity.contributor[0].fullName }}
                </div>
              </div>
            </td>
            <td valign="top" class="activity"><p class="font-normal">{{ activity.name }}</p></td>
            <td valign="top" class="description"><p>{{ activity.description }}</p></td>
            <td valign="top" class="core-skill text-center">
              <img
                v-if="activity.coreSkill[0].icon !== ''"
                :src="activity.coreSkill[0].icon"
                class="inline h-16 object-contain"
              />
              <br />
              {{ activity.coreSkill ? activity.coreSkill[0].name : "" }}
            </td>
            <td valign="top" class="ways-of-learning text-center">
              <img
                v-if="activity.waysOfLearning[0].icon"
                :src="activity.waysOfLearning[0].icon"
                class="inline h-16 object-contain"
              />
              <br />
              {{
                activity.waysOfLearning ? activity.waysOfLearning[0].name : ""
              }}
            </td>
            <td valign="top" class="stream text-center">
              <img
                v-if="activity.streams[0].icon"
                :src="activity.streams[0].icon"
                class="inline h-16 object-contain"
              />
              <br />
              {{ activity.streams ? activity.streams[0].name : "" }}
            </td>
          </tr>
          <tr v-if="plannedActivities.length <= 0">
            <td colspan="7">There are no planned activities for this day.</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="teacher.role == 'teacher'">
      <div class="comment-box">
        <ul v-if="comments.length > 0">
          <li v-for="(comment, index) in comments" :key="index">
            {{ comment.comment }}
          </li>
        </ul>
      </div>
    </div>

    <div class="cmt-container" v-if="teacher.role == 'leadTeacher' && plannedActivities.length > 0">
      <div
        v-if="
          selectedDateCompare == 'equal' ||
            selectedDateCompare == 'greater' ||
            this.previousComment == null
        "
        class="mt-10 mb-0 comment-wrapper md:w-4/5"
      >
        <vs-textarea
          label="Today's comment"
          name="comment"
          v-model="comment"
          v-validate="'required'"
          v-bind:class="{ hasValue: comment }"
        />
        <span class="text-danger text-sm" v-show="errors.has('comment')">
          {{ errors.first("comment") }}
        </span>

        <div class="flex mb-4">
          <div class="w-full bg-grid-color h-12 text-right">
            <vs-button color="primary" type="filled" @click="submitComment"
              >Save
            </vs-button>
          </div>
        </div>
      </div>
      <div v-else-if="selectedDateCompare == 'less'" class="py-8 px-10">
        <div class="comment-box">{{ comment }}</div>
      </div>
      <div v-else></div>
    </div>

    <!-- Full width column -->
    <div class="prnt-btn" v-if="plannedActivities.length > 0 && teacher.role == 'leadTeacher'">
      <div class="w-full bg-grid-color flex justify-end py-8 px-10">
        <a
          href="javascript:void(0)"
          class="preview-btn vs-button mr-10"
          @click="printPreviewOutcome"
          >Preview</a
        >
        <a
          href="javascript:void(0)"
          class="print-btn vs-button vs-button-primary vs-button-filled"
          @click="printOutcome"
          >Print</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { mapActions } from "vuex";
import moment from "moment";


export default {
  components: { Datepicker },
  data() {
    return {
      updating: false,
      checkBoxValue: [],
      comment: "",
      comments: [],
      dateFormat: "dsu MMMM yyyy",
      selectedFormatedDate:"",
      dateTitle: "",
      dailyCommentId: "",
      selectedDate: "",
      plannedActivities: [],
      selectedDateCompare: "equal",
      previousComment: null,
      selectedStartDate: "",
      selectedEndDate: ""
    };
  },
  props: {
    date: "",
    startDate: "",
    endDate: ""
  },
  watch: {
    date(date) {
              this.selectedFormatedDate= moment(date ).format("Do MMMM yyyy")

      this.compareDate();
      this.checkBoxValue = [];
      if (this.teacher.role == "leadTeacher") {
        this.getPlannedActivities(
          this.teacher.roomId,
          this.startDate,
          this.endDate,
          date
        );
      } else {
        this.getPlannedActivitiesForTeacher(
          this.teacher.roomId,
          this.date,
          date
        );
      }

      //this.getTeacherDailyComment(this.teacher._id, date);
    }
    // startDate(startDate) {
    //   alert("sss");
    //   //this.getPlannedActivities(this.teacher.roomId, startDate, startDate);
    //   this.getPlannedActivities(this.teacher.roomId, startDate, this.endDate);
    //   // this.getTeacherDailyComment(this.teacher._id,  this.date);
    // }
  },
  methods: {
    ...mapActions("activity", [
      "fetchPlannedActivities",
      "fetchPlannedActivitiesForTeacher"
    ]),

    ...mapActions("teacher", [
      "addDailyComment",
      "updateDailyComment",
      "getDailyComment"
    ]),
     moment(date) {
      return moment(date);
    },
    compareDate() {
      let todayDate = new Date(); //Today Date
      todayDate.setHours(0, 0, 0, 0);
      let dateOne = new Date(this.selectedDate);
      dateOne.setHours(0, 0, 0, 0);

      if (todayDate > dateOne) {
        this.selectedDateCompare = "less";
      } else if (todayDate < dateOne) {
        this.selectedDateCompare = "greater";
      } else {
        this.selectedDateCompare = "equal";
      }
    },

    printOutcome() {
      if (this.checkBoxValue.length > 0) {
        let toPrint = document.getElementById("print-daily-diary-div");
        let popupWin = window.open(
          "",
          "_blank",
          "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );
        popupWin.document.open();

        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style')
        ]) {
          stylesHtml += node.outerHTML;
        }
        stylesHtml +=
          '<link rel="stylesheet" type="text/css" href="/print.css" />';

        popupWin.document.write(
          "<html><head><title>::Preview::</title><style> .date-div{display: block !important;}</style>" +
            stylesHtml +
            '</head><body onload="window.print()">'
        );
        popupWin.document.write(toPrint.innerHTML);
        popupWin.document.write("</html>");
        popupWin.document.close();
      } else {
        this.errorMessage();
      }
    },
    /*--This JavaScript method for Print Preview command--*/
    printPreviewOutcome() {
      if (this.checkBoxValue.length > 0) {
        let toPrint = document.getElementById("print-daily-diary-div");
        let popupWin = window.open(
          "",
          "_blank",
          "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );
        popupWin.document.open();

        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style')
        ]) {
          stylesHtml += node.outerHTML;
        }
        stylesHtml +=
          '<link rel="stylesheet" type="text/css" href="/print.css" />';

        popupWin.document.write(
          "<html><head><title>::Print Preview::</title><style> .date-div{display: block !important;}</style>" +
            stylesHtml +
            '</head><body">'
        );
        popupWin.document.write(toPrint.innerHTML);
        popupWin.document.write("</html>");
        popupWin.document.close();
      } else {
        this.errorMessage();
      }
    },
    errorMessage() {
      this.$vs.notify({
        title: "Failure",
        text: "Please select at least one activity.",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "danger"
      });
    },
    async getTeacherDailyComment(userId, date) {
      let data = {
        userId: userId,
        date: date,
        type: "plan"
      };
      await this.getDailyComment(data)
        .then(result => {
          let response = result.data.data;
          if (response.length > 0) {
            if (this.teacher.role == "leadTeacher") {
              this.plannedActivities.forEach((activity, index) => {
                if (response[0].activities.includes(activity._id)) {
                  this.checkBoxValue[index] = true;
                }
              });
              this.dailyCommentId = response[0]._id;
              this.comment = response[0].comment;
            } else {
              this.comments = response;
            }
          } else {
            this.dailyCommentId = "";
            this.comment = "";
            this.comments = [];
          }
          // if (!Array.isArray(response)) {
          //   this.plannedActivities.forEach((activity, index) => {
          //     console.log(activity, "activity");
          //     if (response.activities.includes(activity._id)) {
          //       this.checkBoxValue[index] = true;
          //     }
          //   });
          //   this.dailyCommentId = response._id;
          //   this.comment = response.comment;
          // } else {
          //   this.dailyCommentId = "";
          //   this.comment = "";
          // }

          // if (!Array.isArray(response) || !response) {
          //   this.plannedActivities.forEach((activity, index) => {
          //     if (response.activities.includes(activity._id)) {
          //       this.checkBoxValue[index] = true;
          //     }
          //   });

          //   this.dailyCommentId = response._id;
          //   if (response.comment != "") {
          //     this.comment = response.comment;
          //     this.previousComment = response.comment;
          //   }
          //   this.updating = true;
          // } else {
          //   this.dailyCommentId = "";
          //   this.comment = "";
          // }
        })
        .catch(err => {
          // console.log(err)
        });
    },
    async submitComment() {
      let self = this;
      let selectedPlanActivityIds = [];


      this.checkBoxValue.forEach((number, index) => {
        if (number) {
          selectedPlanActivityIds.push(self.plannedActivities[index]._id);
        }
      });

      this.$validator.validateAll().then(result => {
        if (result) {

           this.$vs.loading();
          let obj = {
            date: this.date,
            comment: this.comment,
            userId: this.teacher._id,
            roomId: this.teacher.roomId,
            activities: selectedPlanActivityIds,
            type: "plan"
          };
          if (this.dailyCommentId) {
            obj.commentId = this.dailyCommentId;
            this.updateDailyComment(obj)
              .then(res => {
                this.updating = false;
                this.previousComment = this.comment;
                this.$vs.notify({
                  title: "Success",
                  text: "Data Saved",
                  color: "success",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-check-circle"
                });
                this.$vs.loading.close();
              })
              .catch(err => {
                this.$vs.notify({
                  title: "Error",
                  text: err.message,
                  color: "error",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-times"
                });
                this.$vs.loading.close();
              });
          } else {
            this.addDailyComment(obj)
              .then(res => {
                this.previousComment = this.comment;
                this.dailyCommentId = res.data.data._id;
                this.$vs.notify({
                  title: "Success",
                  text: "Data Saved",
                  color: "success",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-check-circle"
                });
                this.$vs.loading.close();
              })
              .catch(err => {
                this.$vs.notify({
                  title: "Error",
                  text: err.message,
                  color: "error",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-times"
                });
                this.$vs.loading.close();
              });
          }
        }
      });
    },
    async getPlannedActivities(roomId, startDate, endDate, commentDate) {
      this.$vs.loading();
      // let param = "?date=" + date + ;
      let param = "?startDate=" + startDate + "&endDate=" + endDate;
      let data = { roomId: roomId, param: param };

      await this.fetchPlannedActivities(data)
        .then(res => {
          this.$vs.loading.close();
          this.plannedActivities = res.data.data;
          this.getTeacherDailyComment(this.teacher._id, commentDate);
        })
        .catch(err => {
          this.$vs.loading.close();
          if (err.response.status === 403) {
            this.$vs.loading.close();

            alert(
              "You dont have permission to visit this page. Please upgrade your package."
            );
            this.$router.push("/teacher/dashboard");
          }
        });
    },
    async getPlannedActivitiesForTeacher(roomId, date, commentDate) {
      this.$vs.loading();
      let param = "?date=" + date;
      let data = { roomId: roomId, param: param };

      await this.fetchPlannedActivitiesForTeacher(data).then(res => {
        this.$vs.loading.close();
        //console.log(res.data.data, "res.data.data");
        this.plannedActivities = res.data.data;
        this.getTeacherDailyComment(this.teacher._id, commentDate);
      });
    }
  },
  created() {
    this.errors.clear();
    this.$validator.reset();
  },
  async mounted() {
    this.selectedDate = this.date;
    this.selectedStartDate = this.startDate;
    this.selectedEndDate = this.endDate;

        this.selectedFormatedDate= moment(this.date ).format("Do MMMM yyyy")

    this.compareDate();

    //await this.getPlannedActivities(this.teacher.roomId, this.selectedDate, this.selectedDate);

    if (this.teacher.role == "leadTeacher") {
      await this.getPlannedActivities(
        this.teacher.roomId,
        this.selectedStartDate,
        this.selectedEndDate,
        this.selectedDate
      );
    } else {
      await this.getPlannedActivitiesForTeacher(
        this.teacher.roomId,
        this.date,
        this.selectedDate
      );
    }

    //await this.getTeacherDailyComment(this.teacher._id, this.selectedDate);
    this.errors.clear();
  },
  computed: {
    teacher() {
      return this.$store.state.AppActiveUser;
    }
  }
};
</script>
<style lang="scss" scoped>
.fullname {
  width: calc(100% - 65px);
}
</style>
