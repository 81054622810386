<template>
  <div id="print-daily-diary-div">
    <!--<div class="card-header pl-0">
      <div class="datepicker-wrapper hasIcon">
        <vs-icon icon="calendar_today" class="icon"></vs-icon>
        <datepicker :format="dateFormat" v-model="selectedDate" class="w-full"></datepicker>
      </div>
    </div>-->
    <div
      id="data-list-thumb-view"
      class="data-list-container daily-diary-table"
    >
      <h3 class="date-div" style="display: none">
        Outcome of activities for date {{ selectedFormatedDate }}
      </h3>

      <table class="table w-full print-margin">
        <thead>
          <tr>
            <th
              class="daily-dairy-checkbox"
              v-if="teacher.role == 'leadTeacher'"
            ></th>
            <th class="activity">Activity</th>
            <th class="description">Description</th>
            <th class="core-skill text-center">Core Skill</th>
            <th class="ways-of-learning text-center">Ways of Learning</th>
            <th class="stream text-center">STREAM</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="outcomeActivities.length > 0"
            v-for="(activity, index) in outcomeActivities"
            :key="activity._id"
            :class="
              checkBoxValue[index]
                ? 'checked daily-diary-checklist'
                : 'daily-diary-checklist not-checked'
            "
          >
            <td
              valign="top"
              class="daily-dairy-checkbox"
              v-if="teacher.role == 'leadTeacher'"
            >
              <vs-checkbox v-model="checkBoxValue[index]"></vs-checkbox>
            </td>

            <td valign="top" class="activity">{{ activity.name }}</td>
            <!-- <td colspan="4" class="px-0"> -->
            <td valign="top" class="description print-m ">
              <p>{{ activity.description }}</p>
              <div v-if="activity.photos" class="mt-10  ">
                <div class="print-m">
                  <ul class="flex flex-wrap print-img">
                    <li
                      v-for="(photo, index) in activity.photos"
                      :key="photo._id"
                      class="w-1/3 sm:w-1/4 xl:w-2/12 story-image-container photo-item"
                      :class="index > 2 ? 'no-print' : ''"
                    >
                      <div class="thumbnail-wrap">
                        <img
                          v-if="hasAllowedVideoExtension(photo.photo)"
                          :src="convertVideoFileExtenion(photo.photo)"
                        />
                        <img
                          v-if="hasAllowedVideoExtension(photo.photo)"
                          class="playButton"
                          style="max-height: 70px"
                          src="https://personhood.s3.ap-southeast-2.amazonaws.com/undefined/play-button-svgrepo-com.svg"
                        />
                        <img v-else :src="photo.photo" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
            <td valign="top" class="core-skill text-center">
              <img
                v-if="activity.coreSkill[0].icon !== ''"
                :src="activity.coreSkill[0].icon"
                class="inline h-16 object-contain"
              />
              <br />
              <p>{{ activity.coreSkill ? activity.coreSkill[0].name : "" }}</p>
            </td>
            <td valign="top" class="ways-of-learning text-center">
              <img
                v-if="activity.waysOfLearning[0].icon"
                :src="activity.waysOfLearning[0].icon"
                class="inline h-16 object-contain"
              />
              <br />
              <p>
                {{
                  activity.waysOfLearning ? activity.waysOfLearning[0].name : ""
                }}
              </p>
            </td>
            <td valign="top" class="stream text-center">
              <img
                v-if="activity.streams[0].icon"
                :src="activity.streams[0].icon"
                class="inline h-16 object-contain"
              />

              <br />
              <p>{{ activity.streams ? activity.streams[0].name : "" }}</p>
            </td>
            <!-- <table class="inner-table">
              <tbody>
              <tr>
                <td valign="top" class="description print-m">{{ activity.description }}</td>
                <td valign="top" class="core-skill text-center">
                  <img
                    v-if="activity.coreSkill[0].icon !==''"
                    :src="activity.coreSkill[0].icon"
                    class="inline h-16 object-contain"
                  />
                  <br/>
                  {{ activity.coreSkill ? activity.coreSkill[0].name : "" }}
                </td>
                <td valign="top" class="ways-of-learning text-center">
                  <img
                    v-if="activity.waysOfLearning[0].icon"
                    :src="activity.waysOfLearning[0].icon"
                    class="inline h-16 object-contain"
                  />
                  <br/>
                  {{
                    activity.waysOfLearning
                      ? activity.waysOfLearning[0].name
                      : ""
                  }}
                </td>
                <td valign="top" class="stream text-center">
                  <img
                    v-if="activity.streams[0].icon"
                    :src="activity.streams[0].icon"
                    class="inline h-16 object-contain"
                  />

                  <br/>
                  {{ activity.streams ? activity.streams[0].name : "" }}
                </td>
              </tr>
              <tr v-if="activity.photos">
                <td colspan="1" class="print-m">
                  <ul class="flex flex-wrap print-img">
                    <li
                      v-for="photo in activity.photos"
                      :key="photo._id"
                      class="w-1/3"
                    >
                      <img :src="photo.photo" class="w-full h-auto object-contain object-left-top print-view"/>
                    </li>
                  </ul>
                </td>
              </tr>
              </tbody>
            </table> -->
            <!-- </td> -->
          </tr>

          <tr v-if="outcomeActivities.length <= 0">
            <td colspan="5">There are no outcome activities for this day.</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="teacher.role == 'teacher'">
      <div class="comment-box">
        <ul v-if="comments.length > 0">
          <li v-for="(comment, index) in comments" :key="index">
            {{ comment.comment }}
          </li>
        </ul>
      </div>
    </div>
    <div v-if="teacher.role == 'leadTeacher' && outcomeActivities.length > 0">
      <div
        v-if="
          selectedDateCompare == 'equal' ||
            selectedDateCompare == 'greater' ||
            this.previousComment == null
        "
        class="mt-10 mb-0 comment-wrapper md:w-4/5"
      >
        <vs-textarea
          label="Today's comment"
          name="comment"
          v-model="comment"
          v-validate="'required'"
          v-bind:class="{ hasValue: comment }"
        />
        <span class="text-danger text-sm" v-show="errors.has('comment')">
          {{ errors.first("comment") }}
        </span>

        <div class="flex mb-4">
          <div class="w-full bg-grid-color h-12 text-right">
            <vs-button color="primary" type="filled" @click="submitComment"
              >Save
            </vs-button>
          </div>
        </div>
      </div>
      <div v-else-if="selectedDateCompare == 'less'" class="py-8 px-10">
        <div class="comment-box">{{ comment }}</div>
      </div>
      <div v-else></div>
    </div>

    <div v-if="outcomeActivities.length > 0 && teacher.role == 'leadTeacher'">
      <div
        class="w-full bg-grid-color flex justify-end py-8 px-10 tab-content-action"
      >
        <a
          href="javascript:void(0)"
          class="preview-btn vs-button mr-10"
          @click="printPreviewOutcome"
          >Preview</a
        >
        <a
          href="javascript:void(0)"
          class="print-btn vs-button vs-button-primary vs-button-filled"
          @click="printOutcome"
          >Print</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { mapActions } from "vuex";
import moment from "moment";
const ALLOWED_VIDEO_FILETYPES = ["mp4", "mov", "avi", "webm"];

export default {
  components: { Datepicker },
  data() {
    return {
      updating: false,
      checkBoxValue: [],
      selectedDate: "",
      dateFormat: "dsu MMMM yyyy",
      selectedFormatedDate: "",
      outcomeActivities: [],
      selectedDateCompare: "equal",
      dailyCommentId: "",
      previousComment: null,
      selectedStartDate: "",
      selectedEndDate: "",
      comment: "",
      comments: []
    };
  },
  props: {
    date: "",
    startDate: "",
    endDate: ""
  },
  watch: {
    async date(date) {
      this.selectedFormatedDate = moment(date).format("dsu MMMM yyyy");

      // if (this.teacher.role == "leadTeacher") {
      //   // this.getOutcomeActivities(
      //   //   this.teacher.roomId,
      //   //   this.startDate,
      //   //   this.endDate
      //   // );
      // } else {
      //   await this.getOutcomeActivities(this.teacher.roomId, date, date);
      // }

      await this.getOutcomeActivities(this.teacher.roomId, date, date);
      //await this.getTeacherDailyComment(this.teacher._id, date);
    },
    startDate(startDate) {
      //this.getOutcomeActivities(this.teacher.roomId, startDate, this.endDate);
      // if(this.teacher.role =='leadTeacher'){
      //   this.getOutcomeActivities(this.teacher.roomId, startDate, startDate);
      // }
    }
  },
  methods: {
    ...mapActions("activity", [
      "fetchOutcomeActivitiesForLeadTeacher",
      "fetchOutcomeActivities"
    ]),
    ...mapActions("teacher", [
      "addDailyComment",
      "updateDailyComment",
      "getDailyComment"
    ]),
    moment(date) {
      return moment(date);
    },
    /* *********** check video ext *************** */
    hasAllowedVideoExtension(url) {
      const extension = url.split(".").pop();
      return ALLOWED_VIDEO_FILETYPES.includes(extension);
    },

    convertVideoFileExtenion(url) {
      const thumbArray = url.split("/");
      const thumbUrl = thumbArray[4];
      let thumbIndex = null;
      if (thumbUrl.length) {
        const thumbIndexArray = thumbUrl.split(".");
        thumbIndex = thumbIndexArray[0];
      }
      return `${process.env.VUE_APP_IMAGE_URL}undefined/video-thumb/${thumbIndex}.png`;
    },
    async getTeacherDailyComment(userId, date) {
      let data = {
        userId: userId,
        date: date,
        type: "outcome"
      };
      await this.getDailyComment(data).then(result => {
        let response = result.data.data;

        if (response.length > 0) {
          if (this.teacher.role == "leadTeacher") {
            this.outcomeActivities.forEach((activity, index) => {
              if (response[0].activities.includes(activity._id)) {
                this.checkBoxValue[index] = true;
              }
            });
            this.dailyCommentId = response[0]._id;
            this.comment = response[0].comment;
          } else {
            this.comments = response;
          }
        } else {
          this.dailyCommentId = "";
          this.comment = "";
          this.comments = [];
        }

        // if (!Array.isArray(response)) {
        //   console.log(this.outcomeActivities, response.activities, "test");
        //   this.outcomeActivities.forEach((activity, index) => {
        //     if (response.activities.includes(activity._id)) {
        //       this.checkBoxValue[index] = true;
        //     }
        //   });
        //   this.dailyCommentId = response._id;
        //   this.comment = response.comment;
        // } else {
        //   this.dailyCommentId = "";
        //   this.comment = "";
        // }
      });
    },
    compareDate() {
      let todayDate = new Date(); //Today Date
      todayDate.setHours(0, 0, 0, 0);
      let dateOne = new Date(this.selectedDate);
      dateOne.setHours(0, 0, 0, 0);

      if (todayDate > dateOne) {
        this.selectedDateCompare = "less";
      } else if (todayDate < dateOne) {
        this.selectedDateCompare = "greater";
      } else {
        this.selectedDateCompare = "equal";
      }
    },
    async submitComment() {
      let self = this;
      let selectedOutcomeIds = [];

      this.checkBoxValue.forEach((number, index) => {
        if (number) {
          selectedOutcomeIds.push(self.outcomeActivities[index]._id);
        }
      });

      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading();
          let obj = {
            date: this.date,
            comment: this.comment,
            userId: this.teacher._id,
            roomId: this.teacher.roomId,
            activities: selectedOutcomeIds,
            type: "outcome"
          };
          if (this.dailyCommentId) {
            obj.commentId = this.dailyCommentId;
            this.updateDailyComment(obj)
              .then(res => {
                this.updating = false;
                this.previousComment = this.comment;
                this.$vs.notify({
                  title: "Success",
                  text: "Data Saved",
                  color: "success",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-check-circle"
                });
                this.$vs.loading.close();
              })
              .catch(err => {
                this.$vs.notify({
                  title: "Error",
                  text: err.message,
                  color: "error",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-times"
                });
                this.$vs.loading.close();
              });
          } else {
            this.addDailyComment(obj)
              .then(res => {
                this.previousComment = this.comment;
                this.dailyCommentId = res.data.data._id;
                this.$vs.notify({
                  title: "Success",
                  text: "Data Saved",
                  color: "success",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-check-circle"
                });
                this.$vs.loading.close();
              })
              .catch(err => {
                this.$vs.notify({
                  title: "Error",
                  text: err.message,
                  color: "error",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-times"
                });
                this.$vs.loading.close();
              });
          }
        }
      });
    },
    printOutcome() {
      if (this.checkBoxValue.length > 0) {
        let toPrint = document.getElementById("data-list-thumb-view");
        let popupWin = window.open(
          "",
          "_blank",
          "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );
        popupWin.document.open();

        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style')
        ]) {
          stylesHtml += node.outerHTML;
        }
        stylesHtml +=
          '<link rel="stylesheet" type="text/css" href="/print.css" />';

        popupWin.document.write(
          "<html><head><title>::Preview::</title><style>.no-print{display: none;} .date-div{display: block !important;}</style>" +
            stylesHtml +
            '</head><body onload="window.print()">'
        );
        popupWin.document.write(toPrint.innerHTML);
        popupWin.document.write("</html>");
        popupWin.document.close();
      } else {
        this.errorMessage();
      }
    },

    /*--This JavaScript method for Print Preview command--*/
    printPreviewOutcome() {
      if (this.checkBoxValue.length > 0) {
        let toPrint = document.getElementById("data-list-thumb-view");
        let popupWin = window.open(
          "",
          "_blank",
          "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );
        popupWin.document.open();

        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style')
        ]) {
          stylesHtml += node.outerHTML;
        }
        stylesHtml +=
          '<link rel="stylesheet" type="text/css" href="/print.css" />';

        popupWin.document.write(
          "<html><head><title>::Print Preview::</title><style>.no-print{display: none;} .date-div{display: block !important;}</style>" +
            stylesHtml +
            '</head><body">'
        );
        popupWin.document.write(toPrint.innerHTML);
        popupWin.document.write("</html>");
        popupWin.document.close();
      } else {
        this.errorMessage();
      }
    },
    errorMessage() {
      this.$vs.notify({
        title: "Failure",
        text: "Please select at least one activity.",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "danger"
      });
    },
    async getOutcomeActivities(roomId, startDate, endDate) {
      // let param = "?date=" + date;
      let param = "?startDate=" + startDate + "&endDate=" + endDate;
      let data = { roomId: roomId, param: param };

      if (this.teacher.role == "leadTeacher") {
        this.fetchOutcomeActivitiesForLeadTeacher(data)
          .then(res => {
            this.outcomeActivities = res.data.data;
            this.getTeacherDailyComment(this.teacher._id, startDate);
          })
          .catch(err => console.log(err));
      } else {
        this.fetchOutcomeActivities(data)
          .then(res => {
            this.outcomeActivities = res.data.data;
            this.getTeacherDailyComment(this.teacher._id, startDate);
          })
          .catch(err => console.log(err));
      }
    }
  },
  async mounted() {
    this.selectedDate = this.date;
    this.selectedFormatedDate = moment(this.date).format("Do MMMM yyyy");
    this.compareDate();

    // if (this.teacher.role == "leadTeacher") {
    //   // this.getOutcomeActivities(
    //   //   this.teacher.roomId,
    //   //   this.startDate,
    //   //   this.endDate
    //   // );

    //   await this.getOutcomeActivities(
    //     this.teacher.roomId,
    //     this.date,
    //     this.date
    //   );
    // } else {
    //   await this.getOutcomeActivities(
    //     this.teacher.roomId,
    //     this.date,
    //     this.date
    //   );
    // }

    await this.getOutcomeActivities(this.teacher.roomId, this.date, this.date);

    // let self = this;
    // setTimeout(function() {
    //   self.getTeacherDailyComment(self.teacher._id, self.selectedDate);
    // }, 500);

    // console.log("testing", "t");

    // if (this.teacher.role != "leadTeacher") {
    //   this.selectedStartDate = new Date();
    //   this.selectedEndDate = new Date();
    // } else {
    //   this.selectedStartDate = this.selectedDate;
    //   this.selectedEndDate = this.selectedDate;
    // }
    // await this.getOutcomeActivities(
    //   this.teacher.roomId,
    //   this.selectedStartDate,
    //   this.selectedEndDate
    // );
    //await this.getOutcomeActivities(this.teacher.roomId,  this.selectedStartDate, this.selectedEndDate);
  },
  computed: {
    teacher() {
      return this.$store.state.AppActiveUser;
    }
  }
};
</script>

<style lang="scss">
.vs-tooltip {
  z-index: 100000;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.margin-auto {
  margin-top: auto;
  // height: 10px;
}

.vs-tooltip {
  color: #222222;
  font-size: 15px;
  line-height: 20px;
  padding: 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
  min-width: 300px !important;
}

.align-button {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.hidden-web {
  display: none;
}

.photo-item {
  text-align: center;
  position: relative;
}
video {
  max-width: 100%;
  height: 700px;
  &.video-slider {
    height: 100px;
  }
}

.playButton {
  object-fit: contain !important;
  margin: 0;
  text-align: center;
  position: absolute;
  width: 96px;
  height: 96px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.story-image-container {
  position: relative;
}

.thumbnail-wrap {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin: 10px;
  img {
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    &.playButton {
      // transform: translateY(-50%);
      top: 50%;
      left: 50%;
    }
  }
}

[dir="ltr"] .thumbnail-wrap img {
  left: 0;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
</style>
