var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"print-daily-diary-div"}},[_c('div',{staticClass:"data-list-container daily-diary-table plan",attrs:{"id":"data-list-thumb-view"}},[_c('h3',{staticClass:"date-div",staticStyle:{"display":"none"}},[_vm._v("Planned activities for date "+_vm._s(_vm.selectedFormatedDate))]),_c('table',{staticClass:"w-full"},[_c('thead',[_c('tr',[(_vm.teacher.role == 'leadTeacher')?_c('th',{staticClass:"daily-dairy-checkbox"},[_vm._v("   ")]):_vm._e(),_c('th',{staticClass:"contributer"},[_vm._v("Contributor")]),_c('th',{staticClass:"activity"},[_vm._v("Activity")]),_c('th',{staticClass:"description"},[_vm._v("Description")]),_c('th',{staticClass:"core-skill text-center"},[_vm._v("Core Skill")]),_c('th',{staticClass:"ways-of-learning text-center"},[_vm._v("Ways of Learning")]),_c('th',{staticClass:"stream text-center"},[_vm._v("STREAM")])])]),_c('tbody',[_vm._l((_vm.plannedActivities),function(activity,index){return (_vm.plannedActivities.length > 0)?_c('tr',{key:activity._id,class:_vm.checkBoxValue[index]
              ? 'checked daily-diary-checklist'
              : 'daily-diary-checklist not-checked'},[(_vm.teacher.role == 'leadTeacher')?_c('td',{staticClass:"daily-dairy-checkbox",attrs:{"valign":"top"}},[_c('vs-checkbox',{model:{value:(_vm.checkBoxValue[index]),callback:function ($$v) {_vm.$set(_vm.checkBoxValue, index, $$v)},expression:"checkBoxValue[index]"}})],1):_vm._e(),_c('td',{staticClass:"teacher-pic",attrs:{"valign":"top"}},[_c('div',{staticClass:"flex items-center"},[_c('vs-avatar',{staticClass:"ml-0 mr-6",attrs:{"size":"40px","src":'undefined' != typeof activity.contributor
                    ? activity.contributor[0].profileImage
                    : _vm.$defaultImage}}),_c('div',{staticClass:"fullname"},[_vm._v(" "+_vm._s(activity.contributor[0].fullName)+" ")])],1)]),_c('td',{staticClass:"activity",attrs:{"valign":"top"}},[_c('p',{staticClass:"font-normal"},[_vm._v(_vm._s(activity.name))])]),_c('td',{staticClass:"description",attrs:{"valign":"top"}},[_c('p',[_vm._v(_vm._s(activity.description))])]),_c('td',{staticClass:"core-skill text-center",attrs:{"valign":"top"}},[(activity.coreSkill[0].icon !== '')?_c('img',{staticClass:"inline h-16 object-contain",attrs:{"src":activity.coreSkill[0].icon}}):_vm._e(),_c('br'),_vm._v(" "+_vm._s(activity.coreSkill ? activity.coreSkill[0].name : "")+" ")]),_c('td',{staticClass:"ways-of-learning text-center",attrs:{"valign":"top"}},[(activity.waysOfLearning[0].icon)?_c('img',{staticClass:"inline h-16 object-contain",attrs:{"src":activity.waysOfLearning[0].icon}}):_vm._e(),_c('br'),_vm._v(" "+_vm._s(activity.waysOfLearning ? activity.waysOfLearning[0].name : "")+" ")]),_c('td',{staticClass:"stream text-center",attrs:{"valign":"top"}},[(activity.streams[0].icon)?_c('img',{staticClass:"inline h-16 object-contain",attrs:{"src":activity.streams[0].icon}}):_vm._e(),_c('br'),_vm._v(" "+_vm._s(activity.streams ? activity.streams[0].name : "")+" ")])]):_vm._e()}),(_vm.plannedActivities.length <= 0)?_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_vm._v("There are no planned activities for this day.")])]):_vm._e()],2)])]),(_vm.teacher.role == 'teacher')?_c('div',[_c('div',{staticClass:"comment-box"},[(_vm.comments.length > 0)?_c('ul',_vm._l((_vm.comments),function(comment,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(comment.comment)+" ")])}),0):_vm._e()])]):_vm._e(),(_vm.teacher.role == 'leadTeacher' && _vm.plannedActivities.length > 0)?_c('div',{staticClass:"cmt-container"},[(
        _vm.selectedDateCompare == 'equal' ||
          _vm.selectedDateCompare == 'greater' ||
          this.previousComment == null
      )?_c('div',{staticClass:"mt-10 mb-0 comment-wrapper md:w-4/5"},[_c('vs-textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{ hasValue: _vm.comment },attrs:{"label":"Today's comment","name":"comment"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('comment')),expression:"errors.has('comment')"}],staticClass:"text-danger text-sm"},[_vm._v(" "+_vm._s(_vm.errors.first("comment"))+" ")]),_c('div',{staticClass:"flex mb-4"},[_c('div',{staticClass:"w-full bg-grid-color h-12 text-right"},[_c('vs-button',{attrs:{"color":"primary","type":"filled"},on:{"click":_vm.submitComment}},[_vm._v("Save ")])],1)])],1):(_vm.selectedDateCompare == 'less')?_c('div',{staticClass:"py-8 px-10"},[_c('div',{staticClass:"comment-box"},[_vm._v(_vm._s(_vm.comment))])]):_c('div')]):_vm._e(),(_vm.plannedActivities.length > 0 && _vm.teacher.role == 'leadTeacher')?_c('div',{staticClass:"prnt-btn"},[_c('div',{staticClass:"w-full bg-grid-color flex justify-end py-8 px-10"},[_c('a',{staticClass:"preview-btn vs-button mr-10",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.printPreviewOutcome}},[_vm._v("Preview")]),_c('a',{staticClass:"print-btn vs-button vs-button-primary vs-button-filled",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.printOutcome}},[_vm._v("Print")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }